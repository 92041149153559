import { useContext } from 'react'
import { SearchContext, SearchContextProps } from '@lib/contexts/searchContext'

export const useSearchContext = (): SearchContextProps => {
  const context = useContext(SearchContext)
  if (!context) {
    throw new Error('useSearchContext must be used within a SearchContextProvider')
  }
  return context
}
