'use client'

import BookImage from '@components/BookImage'
import { ElasticBook, QuickSearchResultInterface } from '@hermes/types/src/@types/api-legacy/search'
import {
  ArrowBackIcon,
  BookCard,
  Box,
  Button,
  Chevron,
  Chip,
  SearchAltIcon,
  SearchIcon,
  Typography,
  useMediaQuery,
  useTheme
} from '@hermes/web-components'
import { useLocale } from '@hooks/useLocale'
import useTranslateMessage from '@hooks/useTranslateMessage'
import getLanguageFromLocale from '@lib/utils/getLangFromLocale'
import getRegionFromLocale from '@lib/utils/getRegionFromLocale'
import { useRouter } from 'next/navigation'
import { useEffect, useState } from 'react'
import {
  BookCardContainer,
  GenresChipList,
  LeftSearchBlock,
  LeftSearchBlockNoResult,
  NavigationButton,
  RightResultContainer,
  RightSearchBlock,
  SearchMobileList,
  SearchResultContainer,
  SuggestionButton,
  SuggestionsContainer
} from './Result.styles'

interface SearchResultProps extends QuickSearchResultInterface {
  searchValue: string
  hideSearch: () => void
}

export const SuggestionButtonSearch = ({ label, onClick }: { label: string; onClick: () => void }) => {
  const [hover, setHover] = useState(false)
  return (
    <SuggestionButton onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={onClick}>
      <Typography fontWeight={500} component="span">
        {label}
      </Typography>
      {hover && <SearchIcon width="21px" />}
    </SuggestionButton>
  )
}

const SearchResult = ({ searchValue, suggestions, genres, books, authors, hideSearch }: SearchResultProps) => {
  const { t } = useTranslateMessage()
  const router = useRouter()
  const locale = useLocale()
  const lang = getLanguageFromLocale(locale)
  const theme = useTheme()
  const region = getRegionFromLocale(locale)
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const [route, setRoute] = useState('main')
  const env = process.env.NEXT_PUBLIC_ENVIRONMENT
  // eslint-disable-next-line no-nested-ternary
  const stage = env === 'development' ? 'dev-' : env === 'staging' ? 'staging-' : ''
  const [showAllGenres, setShowAllGenres] = useState(false)
  const [showAllAuthors, setShowAllAuthors] = useState(false)

  useEffect(() => {
    if (isDesktop) setRoute('main')
  }, [isDesktop])

  useEffect(() => setRoute('main'), [searchValue])

  const pushToBDP = (book: ElasticBook) => {
    hideSearch()
    const recentViewed = JSON.parse(localStorage.getItem(`recentViewed-${stage}${region}`) || '[]') as ElasticBook[]
    if (recentViewed.length <= 2) {
      recentViewed.unshift(book)
    } else {
      recentViewed.pop()
      recentViewed.unshift(book)
    }
    localStorage.setItem(`recentViewed-${stage}${region}`, JSON.stringify(recentViewed))
    router.push(`/${locale}/books/${book.slug}`)
  }

  const pushToSearch = (value: string) => {
    hideSearch()
    const recentSearch = JSON.parse(localStorage.getItem(`recentSearch-${stage}${region}`) || '[]') as string[]
    if (!recentSearch.includes(value)) {
      if (recentSearch.length <= 2) {
        recentSearch.unshift(value)
      } else {
        recentSearch.pop()
        recentSearch.unshift(value)
      }
      localStorage.setItem(`recentSearch-${stage}${region}`, JSON.stringify(recentSearch))
    }
    router.push(`/${locale}/search?search=${value}&searchView=Grid`)
  }

  const pushToGenre = (genre: string) => {
    router.push(`/${locale}/search/${genre}&searchView=Grid`)
  }

  const pushToAuthor = (id: number) => {
    router.push(`/${locale}/search?author=${id}&searchView=Grid`)
  }

  return (
    <SearchResultContainer>
      {route === 'main' && (
        <LeftSearchBlock>
          {(books && Boolean(books.array.length)) || (suggestions && Boolean(suggestions.length)) ? (
            <>
              {isDesktop ? (
                <SuggestionsContainer>
                  {suggestions &&
                    suggestions.map((item, index) => (
                      <SuggestionButtonSearch onClick={() => pushToSearch(item)} key={index} label={item} />
                    ))}
                </SuggestionsContainer>
              ) : (
                <>
                  {genres && Boolean(genres.length) && (
                    <GenresChipList>
                      {genres.map((item, index) => (
                        <Chip
                          key={index}
                          label={item[lang]}
                          onClick={() => pushToGenre(item.slug)}
                          color="info"
                          variant="filled"
                        />
                      ))}
                    </GenresChipList>
                  )}
                </>
              )}
              <Box>
                {books &&
                  books.array.map((item) => (
                    <BookCardContainer key={item.id}>
                      <BookCard
                        title={`${item.title}${item.subtitle ? `: ${item.subtitle}` : ''}`}
                        cardAction={() => pushToBDP(item)}
                        inline
                        author={item.author ?? undefined}
                        image={
                          <BookImage
                            src={item.image}
                            width={40}
                            height={56}
                            style={{
                              objectFit: 'cover'
                            }}
                            alt={item.title}
                          />
                        }
                        chipLabel={item.priceUsedFrom ? `Kr ${item.priceUsedFrom}` : undefined}
                        cover={t({ id: item.format! })}
                        year={String(item.year)}
                      />
                    </BookCardContainer>
                  ))}
              </Box>
              <Button
                onClick={() => pushToSearch(searchValue)}
                className={isDesktop ? 'show-all-books-button' : 'show-all-books-button-mobile'}
                variant={isDesktop ? 'ghost' : 'outlined'}
              >
                {t(
                  { id: 'header.search.showAll' },
                  {
                    books: books.count
                  }
                )}
              </Button>
            </>
          ) : (
            <LeftSearchBlockNoResult>
              <Box>
                <SearchAltIcon width="32px" />
              </Box>
              <Typography>{t({ id: 'header.search.noResultQuery' })}</Typography>
              <Typography sx={{ wordBreak: 'break-all' }}>{`“${searchValue}”`}</Typography>
            </LeftSearchBlockNoResult>
          )}
          {!isDesktop && (
            <Box mt="16px" display="grid">
              <NavigationButton onClick={() => genres && Boolean(genres.length) && setRoute('categories')}>
                <Typography>{t({ id: 'categories' })}</Typography>
                <Box>
                  <Chip variant="outlined" label={genres ? genres.length : 0} />
                  <Chevron width="20px" style={{ rotate: '-90deg' }} />
                </Box>
              </NavigationButton>
              <NavigationButton onClick={() => authors && Boolean(authors.length) && setRoute('authors')}>
                <Typography>{t({ id: 'authors' })}</Typography>
                <Box>
                  <Chip variant="outlined" label={authors ? authors.length : 0} />
                  <Chevron width="20px" style={{ rotate: '-90deg' }} />
                </Box>
              </NavigationButton>
            </Box>
          )}
        </LeftSearchBlock>
      )}
      {route === 'categories' && (
        <SearchMobileList>
          <Button onClick={() => setRoute('main')}>
            <ArrowBackIcon width="20px" />
            {t({ id: 'categories' })}
          </Button>
          {genres.map((item, index) => (
            <Button key={index} onClick={() => pushToGenre(item.slug)}>
              {item[lang]}
            </Button>
          ))}
        </SearchMobileList>
      )}
      {route === 'authors' && (
        <SearchMobileList>
          <Button onClick={() => setRoute('main')}>
            <ArrowBackIcon width="20px" />
            {t({ id: 'authors' })}
          </Button>
          {authors.map((item, index) => (
            <Button key={index} onClick={() => pushToAuthor(item.id)}>
              {item.name}
            </Button>
          ))}
        </SearchMobileList>
      )}
      {isDesktop && (
        <RightSearchBlock>
          <RightResultContainer>
            <Typography className="right-result-title">{t({ id: 'categories' })}</Typography>
            {genres && genres.length ? (
              <>
                <SuggestionsContainer disableMargin>
                  {genres.slice(0, showAllGenres ? genres.length : 4).map((item, index) => (
                    <SuggestionButton onClick={() => pushToGenre(item.slug)} margin={2} key={index}>
                      <Typography fontWeight={400} component="span">
                        {item[lang]}
                      </Typography>
                    </SuggestionButton>
                  ))}
                </SuggestionsContainer>
                <Button onClick={() => setShowAllGenres(!showAllGenres)} className="show-all-button" variant="ghost">
                  {t({ id: 'showAll' })}
                </Button>
              </>
            ) : (
              <Typography ml="8px" lineHeight="20px" variant="body2" color="text.primary">
                {t({ id: 'header.search.noResults' })}
              </Typography>
            )}
          </RightResultContainer>
          <RightResultContainer>
            <Typography className="right-result-title">{t({ id: 'authors' })}</Typography>
            {authors && authors.length ? (
              <>
                <SuggestionsContainer disableMargin>
                  {authors.slice(0, showAllAuthors ? authors.length : 4).map((item, index) => (
                    <SuggestionButton onClick={() => pushToAuthor(item.id)} margin={2} key={index}>
                      <Typography fontWeight={400} component="span">
                        {item.name}
                      </Typography>
                    </SuggestionButton>
                  ))}
                </SuggestionsContainer>
                <Button onClick={() => setShowAllAuthors(!showAllAuthors)} className="show-all-button" variant="ghost">
                  {t({ id: 'showAll' })}
                </Button>
              </>
            ) : (
              <Typography ml="8px" lineHeight="20px" variant="body2" color="text.primary">
                {t({ id: 'header.search.noResults' })}
              </Typography>
            )}
          </RightResultContainer>
        </RightSearchBlock>
      )}
    </SearchResultContainer>
  )
}

export default SearchResult
