export enum Language {
  SV = 'sv',
  EN = 'en',
  NO = 'no'
}

export default function getLanguageFromLocale(locale: string): Language {
  switch (locale) {
    case 'se': {
      return Language.SV
    }
    case 'no': {
      return Language.NO
    }
    default: {
      return Language.EN
    }
  }
}
