import { styledComponent, TextField, Box } from '@hermes/web-components'

export const StyledSearchArea = styledComponent(TextField)(({ theme }) => ({
  boxShadow: 'rgb(0 0 0 / 8%) 0px 4px 12px',
  border: 'none',
  borderRadius: '10px',
  width: '99.5%',
  padding: '0 12px 0 15px',
  '.search-action-button': {
    svg: { fill: theme.palette.neutral[400] }
  },
  '.Mui-focused': {
    '.MuiButtonBase-root:first-of-type': {
      svg: { fill: theme.palette.neutral[900] }
    }
  },
  [theme.breakpoints.up('md')]: {
    padding: '0 12px 0 16px'
  },
  input: {
    height: '46px',
    margin: '0 12px',
    wordSpacing: '1px',
    textOverflow: 'ellipsis',
    lineClamp: 1,
    '&::placeholder': {
      maxWidth: '90%'
    },
    '&:focus::placeholder': {
      color: 'transparent'
    },
    [theme.breakpoints.up('md')]: {
      height: '48px'
    }
  },
  [theme.breakpoints.down('md')]: {
    border: `1px solid ${theme.palette.neutral[200]}`,
    boxShadow: 'none'
  },
  '.MuiButtonBase-root': {
    minWidth: 'auto',
    svg: {
      margin: 0
    }
  },
  '.clear-search-value': {
    svg: {
      fill: theme.palette.divider
    },
    '&:hover': {
      svg: {
        fill: theme.palette.primary.main
      }
    }
  }
}))

export const SearchAreaContainer = styledComponent(Box)({
  alignSelf: 'center',
  width: '100%',
  position: 'relative'
})
