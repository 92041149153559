import React, { memo } from 'react'
import { BaseImage, BaseImageProps } from './BaseImage'

const DEFAULT_IMAGE = '/img/default-avatar.png'

const Avatar = memo((props: Omit<BaseImageProps, 'fallbackImageSrc'>) => (
  <BaseImage {...props} style={{ borderRadius: '100%', objectFit: 'cover' }} fallbackImageSrc={DEFAULT_IMAGE} />
))

export default Avatar
