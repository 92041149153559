import { Box, Paper, Button, styledComponent } from '@hermes/web-components'

export const SearchResultContainer = styledComponent(Paper)(({ theme }) => ({
  position: 'absolute',
  zIndex: 999,
  width: '684px',
  borderRadius: '8px',
  boxShadow: '0 4px 12px rgb(0 0 0 / 8%)',
  top: 'calc(100% + 6px)',
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.down('sm')]: {
    position: 'fixed',
    zIndex: 9999,
    top: '74px',
    width: '100%',
    height: 'calc(100vh - 74px)',
    left: 0
  }
}))

export const SuggestionButton = styledComponent(Button, {
  shouldForwardProp: (prop) => prop !== 'margin'
})<{ margin?: number }>(({ theme, margin }) => ({
  height: 'auto',
  padding: '6px 8px',
  borderRadius: '8px',
  justifyContent: 'space-between',
  marginBottom: margin ? `${margin}px` : '0',
  '&:last-child': {
    marginBottom: 0
  },
  span: {
    fontSize: '16px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    lineHeight: '24px',
    color: theme.palette.neutral['900']
  },
  svg: {
    fill: theme.palette.neutral.main
  },
  [theme.breakpoints.down('sm')]: {
    padding: '16px 0',
    borderRadius: 0,
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginBottom: '0 !important',
    span: {
      lineHeight: '20px'
    }
  }
}))

export const SuggestionsContainer = styledComponent(Box, {
  shouldForwardProp: (prop) => prop !== 'disableMargin'
})<{ disableMargin?: boolean }>(({ disableMargin }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: disableMargin ? '0' : '8px'
}))

export const BookCardContainer = styledComponent(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: '12px 0',
  '&:last-child': {
    borderBottom: 'none'
  }
}))

export const LeftSearchBlock = styledComponent(Box)(({ theme }) => ({
  padding: '16px',
  overflow: 'hidden',
  width: '65.6%',
  '.book-card': {
    '.book-card-title': {
      WebkitLineClamp: '1'
    }
  },
  '.show-all-books-button': {
    width: '100%',
    padding: 0,
    height: 'auto',
    fontSize: '14px',
    marginTop: '15px',
    lineHeight: '24px',
    wordSpacing: '1px',
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  '.show-all-books-button-mobile': {
    width: '100%',
    marginTop: '15px',
    height: 'auto',
    fontSize: '14px',
    lineHeight: '20px',
    wordSpacing: '1px'
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  }
}))

export const RightSearchBlock = styledComponent(Box)(({ theme }) => ({
  overflowY: 'auto',
  maxHeight: '493px',
  padding: '16px 16px 16px 8px',
  borderLeft: `1px solid ${theme.palette.divider}`,
  width: '34.4%'
}))

export const RightResultContainer = styledComponent(Box)(({ theme }) => ({
  overflow: 'hidden',
  marginBottom: '26px',
  '.right-result-title': {
    fontSize: '14px',
    lineHeight: '20px',
    marginLeft: '8px',
    marginBottom: '5px',
    color: theme.palette.neutral.main
  },
  '.show-all-button': {
    color: theme.palette.primary.main,
    fontSize: '14px',
    fontWeight: 500,
    marginLeft: '8px',
    padding: '0',
    height: 'auto',
    width: 'auto',
    wordSpacing: '1px'
  }
}))

export const LeftSearchBlockNoResult = styledComponent(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  padding: '40px 0',
  '.MuiBox-root': {
    width: '68px',
    height: '68px',
    borderRadius: '100%',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary[100],
    marginBottom: '24px',
    svg: {
      fill: theme.palette.primary.main
    }
  },
  '.MuiTypography-root': {
    fontSize: '14px',
    lineHeight: '16px',
    marginBottom: '3px'
  }
}))

export const GenresChipList = styledComponent(Box)({
  overflowX: 'auto',
  padding: '0 16px 8px',
  display: 'flex',
  margin: '0 -16px',
  '.MuiChip-root': {
    padding: '8px',
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 500,
    height: 'auto',
    marginRight: '4px'
  }
})

export const NavigationButton = styledComponent(Button)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '15px 0',
  height: 'auto',
  borderBottom: `1px solid ${theme.palette.divider}`,
  '&:hover': {
    backgroundColor: 'transparent'
  },
  '.MuiTypography-root': {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 500
  },
  '.MuiBox-root': {
    display: 'inline-flex',
    alignItems: 'center',
    svg: {
      marginLeft: '4px',
      fill: theme.palette.neutral.main
    }
  }
}))

export const SearchMobileList = styledComponent(Box)(({ theme }) => ({
  width: '100%',
  overflowY: 'auto',
  '.MuiButtonBase-root': {
    color: theme.palette.neutral[900],
    width: 'calc(100% - 32px)',
    borderBottom: `1px solid ${theme.palette.divider}`,
    height: 'auto',
    justifyContent: 'start',
    margin: '0 16px 2px 16px',
    padding: '16px 0',
    fontWeight: 400,
    lineHeight: '20px',
    wordSpacing: '1px',
    textAlign: 'start',
    '&:first-of-type': {
      width: '100%',
      lineHeight: '24px',
      fontWeight: 500,
      margin: '0',
      padding: '16px',
      svg: {
        marginRight: '12px'
      }
    }
  }
}))
