import Avatar from '@components/Avatar'
import { MobileMenuLink, NavigationButton } from '@components/Header.styles'
import { UserInterface } from '@hermes/types/src/@types/api-legacy/user'
import {
  BookIcon,
  Box,
  Button,
  CloseIcon,
  Drawer,
  ListIcon,
  LogoutIcon,
  MenuIcon,
  MoneyIcon,
  Paper,
  SettingsIcon,
  Typography,
  useClickOutside,
  useMediaQuery,
  useTheme
} from '@hermes/web-components'
import { useLocale } from '@hooks/useLocale'
import useTranslateMessage from '@hooks/useTranslateMessage'
import { useUserToken } from '@hooks/useUserToken'
import { useRouter } from 'next/navigation'
import React, { useRef, useState } from 'react'
import {
  MobileMenuBody,
  MobileMenuHeader,
  UserMenuItem,
  UserMenuMobileSX,
  UserMenuNumerics,
  UserMenuSX,
  UserMenuTitle
} from './User.styles'

interface UserAreaProps {
  user: UserInterface | null
}

const UserArea = ({ user }: UserAreaProps) => {
  const theme = useTheme()
  const router = useRouter()
  const locale = useLocale()
  const [open, setOpen] = useState(false)
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { up } = theme.breakpoints
  const desktopMenuRef = useRef()
  const { token } = useUserToken()
  const { t } = useTranslateMessage()

  const logOut = () => {
    window.location.href = `${window.location.origin}/${locale}/logout`
  }

  const menuItems = [
    {
      icon: <BookIcon width="20px" />,
      label: t({ id: 'profileMenu.forSales' }),
      href: '/profile/sales'
    },
    {
      icon: <MoneyIcon width="20px" />,
      label: t({ id: 'profileMenu.purchases' }),
      href: '/profile/purchases'
    },
    {
      icon: <ListIcon width="20px" />,
      label: t({ id: 'profileMenu.lists' }),
      href: '/profile/lists'
    },
    {
      icon: <SettingsIcon width="20px" />,
      label: t({ id: 'profileMenu.setting' }),
      href: '/profile/settings/account'
    },
    {
      icon: <LogoutIcon width="20px" />,
      label: t({ id: 'profileMenu.logout' }),
      action: logOut
    }
  ]

  const mobileMenuLinksUnauthorized = [
    {
      label: t({ id: 'header.sellBooks' }),
      href: '/add-sale'
    },
    {
      label: t({ id: 'header.signInOrRegister' }),
      href: '/auth'
    }
  ]

  const mobileMenuLinks = [
    {
      label: t({ id: 'header.sellBooks' }),
      href: '/add-sale'
    },
    {
      label: t({ id: 'messages' }),
      href: '/my-zone/messages'
    },
    {
      label: t({ id: 'profileMenu.lists' }),
      href: '/profile/lists?fromMobileMenu=true'
    },
    {
      label: t({ id: 'profileMenu.forSale' }),
      href: '/profile/sales?fromMobileMenu=true'
    },
    {
      label: t({ id: 'profileMenu.title' }),
      href: '/profile/settings/account?fromMobileMenu=true'
    },
    {
      label: t({ id: 'profileMenu.logout' }),
      action: logOut
    }
  ]

  const handleOpen = () => setOpen(!open)
  const handleClose = () => setOpen(false)

  useClickOutside(desktopMenuRef, () => handleClose())

  const MenuItem = ({
    icon,
    label,
    href,
    action
  }: {
    icon: React.ReactNode
    label: string
    href?: string
    action?: () => void
  }) => (
    <UserMenuItem
      onClick={() => {
        if (href) router.push(`/${locale}${href}`)
        if (action) action()
      }}
    >
      {icon}
      <Typography variant="body2">{label}</Typography>
    </UserMenuItem>
  )

  const DesktopMenu = () => (
    <>
      {user && (
        <Box ref={desktopMenuRef} width={'150px'}>
          <NavigationButton sx={{ padding: '0 12px' }} active={open} onClick={handleOpen}>
            <Box minWidth="28px" height="28px" position="relative">
              <Avatar
                quality={75}
                style={{ borderRadius: '100%' }}
                src={user.imageUrl}
                width={28}
                height={28}
                alt={`${user.firstName} ${user.lastName}`}
              />
            </Box>
            <Typography ml="8px" variant="body2">
              {t({ id: 'profileMenu.title' })}
            </Typography>
          </NavigationButton>
          <>
            {open && (
              <Box>
                <Paper sx={UserMenuSX}>
                  <UserMenuTitle>
                    <Box mr="16px" position="relative" width="48px" height="48px">
                      <Avatar
                        quality={30}
                        style={{ borderRadius: '100%' }}
                        src={user.imageUrl}
                        fill
                        alt={`${user.firstName} ${user.lastName}`}
                      />
                    </Box>
                    <Box>
                      <Typography color="#000000" variant="body2">{`${user.firstName} ${user.lastName}`}</Typography>
                      <UserMenuNumerics>
                        {t(
                          { id: 'profileMenu.userBooksInfo' },
                          {
                            sales: user.numeric?.sales,
                            lists: user.numeric?.lists
                          }
                        )}
                      </UserMenuNumerics>
                    </Box>
                  </UserMenuTitle>
                  {menuItems.map((item, index) => (
                    <MenuItem key={index} {...item} />
                  ))}
                </Paper>
              </Box>
            )}
          </>
        </Box>
      )}
    </>
  )

  const MobileMenu = () => (
    <Drawer
      open={open}
      anchor="right"
      hideBackdrop
      PaperProps={{
        sx: UserMenuMobileSX
      }}
    >
      <MobileMenuHeader>
        <Typography variant="body2">{t({ id: 'menu' })}</Typography>
        <Button onClick={handleClose} variant="ghost">
          <CloseIcon width="25px" />
        </Button>
      </MobileMenuHeader>
      <MobileMenuBody>
        {(token ? mobileMenuLinks : mobileMenuLinksUnauthorized).map(
          (item: { href?: string; label: string; action?: () => void }, index) =>
            item.action ? (
              <Button key={index} variant="ghost" onClick={item.action}>
                <Typography variant="h2">{item.label}</Typography>
              </Button>
            ) : (
              <MobileMenuLink key={index} href={`/${locale}${item.href}`}>
                {/* <Typography variant="h2">{item.label}</Typography> */}
                {item.label}
              </MobileMenuLink>
            )
        )}
      </MobileMenuBody>
    </Drawer>
  )

  return (
    <>
      <Button
        aria-label="menu"
        onClick={handleOpen}
        variant="ghost"
        sx={{ [up('md')]: { display: 'none' }, paddingLeft: '18px', paddingRight: '18px' }}
      >
        <MenuIcon width="25px" />
      </Button>

      {isMobile ? MobileMenu() : DesktopMenu()}
    </>
  )
}

export default UserArea
