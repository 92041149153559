import { styledComponent, Box, Typography, MenuItem } from '@hermes/web-components'

export const UserMenuTitle = styledComponent(Box)({
  marginBottom: '16px',
  display: 'inline-flex',
  width: '100%'
})

export const UserMenuNumerics = styledComponent(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '12px',
  lineHeight: '24px'
}))

export const UserMenuItem = styledComponent(MenuItem)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  padding: '16px 0',
  '&:hover': {
    backgroundColor: 'transparent',
    fill: theme.palette.primary.main,
    '.MuiTypography-root': {
      color: theme.palette.primary.main
    }
  },
  svg: {
    marginRight: '10px'
  }
}))

export const MobileMenuHeader = styledComponent(Box)(({ theme }) => ({
  display: 'inline-flex',
  justifyContent: 'space-between',
  padding: '16px',
  borderBottom: `1px solid ${theme.palette.divider}`,
  '.MuiTypography-root': {
    lineHeight: '25px'
  }
}))

export const MobileMenuBody = styledComponent(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '33px 16px',

  '.MuiTypography-root': {
    marginBottom: '16px',
    lineHeight: '36px',
    fontSize: '28px'
  }
})

export const UserMenuSX = {
  position: 'absolute',
  backgroundColor: 'neutral.000',
  padding: '16px 16px 0 16px',
  width: '267px',
  maxWidth: '267px',
  right: '16px',
  top: 'calc(100% + 11px)',
  borderRadius: '16px',
  zIndex: 999,
  boxShadow: '0 8px 24px rgb(0 0 0 / 8%)',
  '.MuiList-root': {
    padding: '0'
  }
}

export const UserMenuMobileSX = {
  minWidth: '100%',
  boxShadow: 'none',
  backgroundImage: 'url(/img/sidebar-bg.jpg)',
  backgroundPosition: '100% 100%',
  backgroundRepeat: 'no-repeat'
}
