import { ExtendedBook } from '@hermes/types'
import { Region } from './getRegionFromLocale'

export const setRecentViewedVariant = (book: ExtendedBook, region: Region, stage: 'dev-' | 'staging-' | '') => {
  const recentViewedToSave: ExtendedBook[] = JSON.parse(localStorage.getItem(`recentViewed-${stage}${region}`) || '[]')
  const bookIsDuplicateRecent = recentViewedToSave.some((element) => JSON.stringify(element) === JSON.stringify(book))
  if (bookIsDuplicateRecent) return

  if (recentViewedToSave.length <= 2) {
    recentViewedToSave.unshift(book)
  } else {
    recentViewedToSave.pop()
    recentViewedToSave.unshift(book)
  }
  localStorage.setItem(`recentViewed-${stage}${region}`, JSON.stringify(recentViewedToSave))
}

export const setRecentSearch = (value: string, region: Region, stage: 'dev-' | 'staging-' | '') => {
  const recentSearch = JSON.parse(localStorage.getItem(`recentSearch-${stage}${region}`) || '[]') as string[]
  if (value) {
    if (recentSearch.length >= 3) {
      recentSearch.pop()
    }
    recentSearch.unshift(value)
    localStorage.setItem(`recentSearch-${stage}${region}`, JSON.stringify(recentSearch))
  }
}
