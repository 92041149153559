import { BookIcon, Box, Logo, LogoMobile, Typography, useTheme } from '@hermes/web-components'
import BookHeartIcon from '@hermes/web-components/src/icons/BookHeart'
import { useLocale } from '@hooks/useLocale'
import { navigationPaths, useNavigation } from '@hooks/useNavigation'
import useTranslateMessage from '@hooks/useTranslateMessage'
import { useUser } from '@lib/contexts/userContext'
import getLanguageFromLocale from '@lib/utils/getLangFromLocale'
import { Locale } from '@lib/utils/localeHelpers'
import Link from 'next/link'
import { useRouter } from 'next/navigation'
import Skeleton from 'react-loading-skeleton'
import {
  CategoriesButtonContainer,
  HeaderAppBar,
  HeaderContainer,
  ItemsAreaContainer,
  ItemsList,
  LogoContainer,
  MessagesNumeric,
  NavigationButton,
  SearchAreaContainer
} from './Header.styles'
import { SearchField } from './SearchField'
import UserArea from './User'

export const Header = ({ localeOverride }: { localeOverride?: Locale }) => {
  const theme = useTheme()
  const { down } = theme.breakpoints
  const router = useRouter()
  const localeFromHook = useLocale()
  const locale = localeOverride || localeFromHook
  const { user, isLoading } = useUser()
  const { t } = useTranslateMessage()
  const lang = getLanguageFromLocale(locale)
  const navigation = useNavigation()

  const CategoriesButton = () => (
    <Link
      href={
        lang === 'no' ? `/${locale}${navigationPaths.explorePage()}` : `/${locale}/${navigationPaths.categoriesPage()}`
      }
      aria-label="explore"
    >
      <NavigationButton
        sx={{ [down('sm')]: { display: 'none' }, ...(lang !== 'no' ? { padding: '0 12px' } : {}), padding: '0px' }}
      >
        {lang === 'no' ? (
          <CategoriesButtonContainer>
            <BookHeartIcon style={{ position: 'relative', top: '0px' }} width="20px" />
            <Typography variant="body2">{t({ id: 'categories.title' })}</Typography>
          </CategoriesButtonContainer>
        ) : (
          <CategoriesButtonContainer>
            <BookIcon style={{ position: 'relative', top: '0px' }} width="20px" />
            <Typography variant="body2">{t({ id: 'categories' })}</Typography>
          </CategoriesButtonContainer>
        )}
      </NavigationButton>
    </Link>
  )
  return (
    <HeaderAppBar position="static">
      <HeaderContainer
        sx={{
          maxWidth: {
            xs: '100%',
            md: 'xl'
          }
        }}
      >
        <Link
          href={`/${locale}`}
          aria-label="home"
          onTouchStart={() => navigation.navigate.homePage()}
          prefetch
          style={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            zIndex: '10'
          }}
        >
          <LogoContainer forMobile={true} paddingRight={'16px'} paddingLeft={'16px'}>
            <LogoMobile />
          </LogoContainer>
          <LogoContainer ml="1px" mr="15px">
            <Logo width={143} height={44} />
          </LogoContainer>
        </Link>
        <SearchAreaContainer>
          <CategoriesButton />
          <SearchField />
        </SearchAreaContainer>
        <ItemsAreaContainer>
          <ItemsList>
            <NavigationButton sx={{ padding: '0 12px' }} onClick={() => router.push(`/${locale}/add-sale`)}>
              <Typography variant="body2">{t({ id: 'header.sellBooks' })}</Typography>
            </NavigationButton>
            {isLoading ? (
              <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Skeleton width={'300px'} height={'40px'} />
              </Box>
            ) : (
              <>
                {user && (
                  <NavigationButton
                    sx={{ padding: '0 12px', width: '150px' }}
                    onClick={() => router.push(`/${locale}/my-zone/messages`)}
                  >
                    <Typography variant="body2">{t({ id: 'messages' })}</Typography>
                    {Boolean(user.numeric?.unread) && <MessagesNumeric>{user.numeric?.unread}</MessagesNumeric>}
                  </NavigationButton>
                )}
                {!user && (
                  <NavigationButton sx={{ padding: '0 12px' }} onClick={() => router.push(`/${locale}/auth`)}>
                    <Typography variant="body2">{t({ id: 'header.signInOrRegister' })}</Typography>
                  </NavigationButton>
                )}
              </>
            )}
          </ItemsList>
          <UserArea user={user} />
        </ItemsAreaContainer>
      </HeaderContainer>
    </HeaderAppBar>
  )
}
