import Link from 'next/link'
import { styledComponent, Container, Box, Button } from '@hermes/web-components'

type BoxProps = typeof Box.arguments
type ButtonProps = typeof Button.arguments

interface StyledButtonProps extends ButtonProps {
  active?: boolean
}

export const NavigationButton = styledComponent(Button, {
  shouldForwardProp: (prop) => prop !== 'active'
})<StyledButtonProps>(({ theme, active }) => ({
  position: 'relative',
  height: '100%',
  boxSizing: 'border-box',
  '&:hover': {
    top: '0.5px',
    backgroundColor: 'transparent',
    borderBottom: `1px solid ${theme.palette.primary.main}`
  },
  top: active ? '0.5px' : '0',
  borderBottom: active ? `1px solid ${theme.palette.primary.main}` : 'none',
  '.MuiTypography-root': {
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '20px',
    color: theme.palette.neutral[600]
  }
}))

export const HeaderAppBar = styledComponent(Box)(({ theme }) => ({
  height: '93px',
  borderRadius: '0',
  backgroundColor: '#ffffff',
  borderBottom: `1px solid ${theme.palette.divider}`,
  [theme.breakpoints.down('md')]: {
    height: '57px'
  }
}))

export const HeaderContainer = styledComponent(Container)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  position: 'relative',
  height: '100%',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'none',
    maxWidth: '100%',
    paddingLeft: '0px',
    paddingRight: '0px'
  }
}))

export interface LogoContainerProps extends BoxProps {
  forMobile?: boolean
}

export const LogoContainer = styledComponent(Box, {
  shouldForwardProp: (prop) => prop !== 'forMobile'
})<LogoContainerProps>(({ theme, forMobile }) => ({
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints[forMobile ? 'up' : 'down']('md')]: {
    display: 'none'
  }
}))

export const SearchAreaContainer = styledComponent(Box)(({ theme }) => ({
  display: 'flex',
  maxWidth: '590px',
  width: '100%',
  gap: '52.60px',
  [theme.breakpoints.down('md')]: {
    gap: '16px'
  },
  [theme.breakpoints.down('sm')]: {
    gap: '0px'
  }
}))

export const MessagesNumeric = styledComponent(Box)(({ theme }) => ({
  marginLeft: '8px',
  borderRadius: '100%',
  backgroundColor: theme.palette.error.main,
  color: '#fff',
  fontSize: '12px',
  lineHeight: '16px',
  fontWeight: 500,
  padding: '4px 9px'
}))

export const ItemsAreaContainer = styledComponent(Box)(({ theme }) => ({
  display: 'inline-flex',
  [theme.breakpoints.down('md')]: {
    marginLeft: 'auto'
  }
}))

export const ItemsList = styledComponent(Box)(({ theme }) => ({
  display: 'inline-flex',
  [theme.breakpoints.down('md')]: {
    display: 'none'
  }
}))

export const CategoriesButtonContainer = styledComponent(Box)(({ theme }) => ({
  display: 'inline-flex',
  gap: '8px',
  alignItems: 'center',
  '.new-label': {
    backgroundColor: theme.palette.tertiary[300],
    padding: '8px',
    borderRadius: '50px',
    minWidth: '70px',
    p: {
      fontWeight: '500',
      fontSize: '12px',
      lineHeight: '16px',
      color: theme.palette.neutral[600],
      whiteSpace: 'nowrap'
    }
  }
}))

export const MobileMenuLink = styledComponent(Link)(({ theme }) => ({
  fontFamily: 'PT Serif, serif',
  fontWeight: 700,
  fontSize: '28px',
  textDecoration: 'none',
  color: theme.palette.neutral[900],
  marginBottom: '16px',
  lineHeight: '36px'
}))
